// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import computerLab from "../../../../../assets/images/computer-lab.webp";
import Lab from "../../../../../assets/images/lab.png";
import ictLab from "../../../../../assets/images/ict-lab.webp";
import Library from "../../../../../assets/images/library.webp";
import Hall from "../../../../../assets/images/hall.webp";
import LanguageLab from "../../../../../assets/images/language-lab.webp";
import NaturalScienceLab from "../../../../../assets/images/natural-science-lab.webp";
import PhysicalScienceLab from "../../../../../assets/images/physical-science-lab.webp";
// Material Kit 2 React examples
import DefaultInfoCard from "../../../../../examples/Cards/InfoCards/DefaultInfoCard";

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox/index";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginBottom: 25,
}));
function Information() {
  return (
    <MKBox component="section" py={4}>
      <Container>
        <div className="row d-flex align-items-center mb-3">
          <div className="col-md-4">
            <img src={computerLab} className="img-fluid" alt="computerLab" />
          </div>
          <div className="col-md-8">
            <h3 className="sam_heading"> Computer Lab</h3>
            <p className="body-text">
              There is a well equipped computer laboratory with internet facilities to provide
              fundamental lessons in computer to student teachers. They are also given necessary
              training in using computer as an effective aid in instruction.
            </p>
          </div>
        </div>
        <div className="row d-flex align-items-center mb-3 flex-column-reverse flex-md-row">
          <div className="col-md-8">
            <h3 className="sam_heading"> Laboratories</h3>
            <p className="body-text">
              Along with theoretical knowledge regarding various facts of education, practical
              knowledge is also highly essential to attain teaching competency. With this view in
              mind, the college has set up well equipped laboratories viz. Language laboratory,
              Technology Laboratory, Psychology laboratory, Physical Science laboratory and Natural
              Science laboratory. In these laboratories the student teachers are provided
              opportunities to do practical work under the guidance of the concerned teachers.
            </p>
          </div>
          <div className="col-md-4">
            <img src={NaturalScienceLab} className="img-fluid" alt="computerLab" />
          </div>
        </div>
        <div className="row d-flex align-items-center mb-3">
          <div className="col-md-4">
            <img src={ictLab} className="img-fluid" alt="computerLab" />
          </div>
          <div className="col-md-8">
            <h3 className="sam_heading"> ICT Lab</h3>
            <p className="body-text">
              ICT Lab is furnished with 35 seats and internet access. It has facilities for using
              various audio visual equipments.
            </p>
          </div>
        </div>
        <div className="row d-flex align-items-center mb-3 flex-column-reverse flex-md-row">
          <div className="col-md-8">
            <h3 className="sam_heading"> Psychology Lab</h3>
            <p className="body-text">
              The college maintains a well furnished psychology lab with a variety of psychological
              tests and equipments.
            </p>
          </div>
          <div className="col-md-4">
            <img src={NaturalScienceLab} className="img-fluid" alt="computerLab" />
          </div>
        </div>
        <div className="row d-flex align-items-center mb-3">
          <div className="col-md-4">
            <img src={PhysicalScienceLab} className="img-fluid" alt="computerLab" />
          </div>
          <div className="col-md-8">
            <h3 className="sam_heading"> Physical Science and Natural Science Laboratories</h3>
            <p className="body-text">
              Along with theoretical knowledge, practical knowledge is also highly essential.
              Student teachers are given opportunities to do practical work under the guidance of
              concerned teachers.
            </p>
          </div>
        </div>
        <div className="row d-flex align-items-center mb-3 flex-column-reverse flex-md-row">
          <div className="col-md-8">
            <h3 className="sam_heading"> Library</h3>
            <p className="body-text">
              The institution maintains a good library with large collection of books. The
              institution subscribes 14 journals and 21 periodicals.
              <ul>
                <li>Sitting capacity-40 </li>
                <li>Number of books- 8900</li>
                <li>Number of titles - 7567</li>
                <li>Number of reference like encyclopedia -237</li>
              </ul>
            </p>
          </div>
          <div className="col-md-4">
            <img src={Library} className="img-fluid" alt="computerLab" />
          </div>
        </div>
        <div className="row d-flex align-items-center mb-3">
          <div className="col-md-4">
            <img src={LanguageLab} className="img-fluid" alt="computerLab" />
          </div>
          <div className="col-md-8">
            <h3 className="sam_heading"> Language Lab</h3>
            <p className="body-text">
              A well equipped language lab is maintained in the institution.
            </p>
          </div>
        </div>
        <div className="row d-flex align-items-center  mb-3 mt-5 infra-last-row">
          <div className="col-md-8">
            <h3 className="sam_heading">FACILITIES ADDED DURING THE LAST QUARTER</h3>
            <ul>
              <li>Printer to the office.</li>
              <li>Sanitary Incinerator .</li>
              <li>Digital Smart Board.</li>
              <li>Replaced old 2 Punching Machines with 2 new Machines</li>
            </ul>
      
          </div>
        </div>
      </Container>
    </MKBox>
  );
}

export default Information;
